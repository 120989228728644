<template>
  <div class="md-search-pages-component">
    <div class="search-box">
      <div class="search-con">
        <el-input placeholder="请输入搜索关键词" v-model="params.searchVal" class="input-with-select" clearable>
          <el-button slot="append" icon="el-icon-search" @click="selectVideoAllList"></el-button>
        </el-input>
      </div>
    </div>
    <div class="md-class-con">
      <div class="md-class-container">
        <ul class="md-class-list" v-if="courseList.length">
          <li v-for="(item, index) in courseList" :key="index">
            <MdItemClassComponent :classInfo="item" />
          </li>
        </ul>
        <p v-else class="no-data">暂无数据</p>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination @current-change="handleCurrentChange" :current-page="+params.pageNum" :page-size="params.pageSize" layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectVideoAllList } from '@/axios/home'
import MdItemClassComponent from "../components/class/mdItem/index.vue";
export default {
  name: 'md-search-pages-component',
  components: {
    MdItemClassComponent
  },
  data () {
    return {
      params: {
        searchVal: '',
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      courseList: []
    }
  },
  created () {
    this.params.searchVal = this.$route.query.keyword
    this.selectVideoAllList()
  },
  methods: {
    async selectVideoAllList () {
      const res = await selectVideoAllList(this.params)
      if (res.code === 200) {
        this.courseList = res.rows
        this.total = res.total
      }
    },
    handleCurrentChange (val) {
      this.params.pageNum = val
      this.selectVideoAllList()
    }
  }
}
</script>
<style lang="less" scoped>
.md-search-pages-component {
  width: 100%;
  .search-box {
    margin: 100px 0;
    width: 100%;
    height: 80px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .search-con {
      width: 500px;
      margin: 0 auto;
      /deep/ .el-input-group__append {
        color: #F20D0D;
        i {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
  .md-class-con {
    width: 100%;
    background: #fff;
    .md-class-container {
      width: 1152px;
      margin: 0 auto;
      padding: 40px 0;
      .md-class-list {
        display: flex;
        flex-wrap: wrap;
        li {
          &:nth-child(4n) {
            .md-item-class-component {
              margin-right: 0;
            }
          }
        }
      }
      .pagination {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.no-data {
  color: #999;
  font-size: 12px;
  padding: 30px 0;
  text-align: center;
}
/deep/ .el-pager li.active {
  color: @md-primary-color;
}
/deep/ .el-pager li:hover {
  color: @md-primary-color;
}
/deep/ .el-input__inner:focus {
  border-color: @md-primary-color !important;
}
/deep/ .el-pagination button:hover {
  color: @md-primary-color;
}
</style>